import tomato from "@/assets/home/tomato-eyes.png";
import objectives from "@/assets/home/objectives.png";
import SVGSeparator from "./SVGSeparator";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";

const Item = ({
  value,
  children,
  visible,
}: {
  value: number;
  children: string;
  visible: boolean;
}) => {
  return (
    <li className="flex gap-4 p-4 bg-[#e6ac2e] mb-4 items-center">
      <div className="text-5xl font-bold text-white font-sans">
        {visible && <CountUp end={value} duration={2} separator="." />}
      </div>
      <p className="text-white text-xl font-semibold">{children}</p>
    </li>
  );
};

const OurImpact = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  return (
    <>
      <div ref={ref} className="pb-0 bg-[#f6e9dd]" id="impact-section">
        <div className="max-w-6xl mx-auto pt-20 px-4">
          <h4 className="text-center md:text-left text-4xl mb-2 font-bold">
            IMPACTO AMBIENTAL
          </h4>
          <p className="mb-8 text-xl">Conoce nuestro impacto ambiental</p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-10">
            <ul>
              <Item visible={inView} value={400}>
                Toneladas de alimentos rescatados
              </Item>
              <Item visible={inView} value={196}>
                Millones de litros de agua que no se perdieron
              </Item>
              <Item visible={inView} value={578}>
                Toneladas de CO2 que no se emitieron
              </Item>
              <Item visible={inView} value={1520}>
                Kg de alimentos donados a Gastronomía Social y sus hogares
                asociados
              </Item>
            </ul>
            <div className="flex justify-center">
              <img
                src={tomato}
                className=" w-4/12 md:w-auto"
                alt="Un tomate con ojos"
              />
            </div>
          </div>
          <div className="flex justify-center">
            <a
              href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={objectives}
                alt="Logo objetivos de desarrollo sostenible de la ONU"
              />
            </a>
          </div>
        </div>
      </div>
      <SVGSeparator />
    </>
  );
};

export default OurImpact;
