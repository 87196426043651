import errorBoxIcon from "@/assets/error_box.png";
import { LayoutDefault } from "@/components";
import { Button } from "@/components/ui/button";
import { Link, useRouter } from "@tanstack/react-router";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const NotFound = () => {
  const { history } = useRouter();

  useEffect(() => {
    Sentry.captureMessage(`Page not found: ${location.toString()}`);
  }, []);

  return (
    <LayoutDefault>
      <div className="flex flex-col items-center">
        <div className="max-w-80">
          <img src={errorBoxIcon} alt="Caja vacia" />
        </div>
        <h4 className="text-3xl mb-2">Página no encontrada</h4>
        <div className="text-center py-4 space-x-6">
          <Button onClick={() => history.go(-1)}>Volver atrás</Button>
          <Button variant="outline">
            <Link to="/">Ir al inicio</Link>
          </Button>
        </div>
      </div>
    </LayoutDefault>
  );
};

export default NotFound;
