import { Link } from "@tanstack/react-router";
import step1 from "@/assets/home/step-1.png";
import step2 from "@/assets/home/step-2.png";
import step3 from "@/assets/home/step-3.png";
import stepArrow from "@/assets/home/step-arrow.png";

const HowItWorks = () => {
  return (
    <div className="py-20 bg-[#7b7d2b]" id="how-it-works-section">
      <div className="max-w-7xl mx-auto">
        <h4 className="text-center text-3xl mx-auto max-w-[600px] font-bold py-0 mb-2 text-white">
          ¿CÓMO FUNCIONA?
        </h4>
        <p className="text-center mb-10 text-xl text-white">
          ¡Comer saludable, más fácil imposible!
        </p>
        <div className="flex flex-col md:flex-row items-center gap-2 mb-16 text-white">
          <div className="">
            <img src={step1} alt="Caja de Maifud" className="w-2/4 mx-auto" />
            <p className="text-center text-lg font-semibold">Elige tu caja</p>
          </div>

          <div className=" w-16 md:w-28 rotate-90 py-6 md:py-0 md:rotate-0 md:-translate-x-10">
            <img src={stepArrow} alt="" />
          </div>

          <div className="">
            <img
              src={step2}
              alt="Imagen de un calendario"
              className="w-2/4 mx-auto"
            />
            <p className="text-center text-lg font-semibold">
              Escoge tu frecuencia y el día en que quieres recibirla.
            </p>
          </div>

          <div className="w-16 md:w-28 rotate-90 py-6 md:py-0 md:rotate-0 md:translate-x-10">
            <img src={stepArrow} alt="" />
          </div>

          <div className="">
            <img
              src={step3}
              alt="Repartidor de Maifud entregando caja a cliente"
              className="w-2/4 mx-auto"
            />
            <p className="text-center text-lg font-semibold">
              Disfruta de tu caja
            </p>
          </div>
        </div>
        <p className="text-center text-xl max-w-[600px] mx-auto p-4 font-semibold text-white leading-tight mb-6">
          <a
            href="https://www.maifud.cl/despacho"
            className="underline"
            target="_blank"
          >
            Puedes pedir tu suscripción directamente a la puerta de tu casa o
            retirar en los puntos de retiro asociados.
          </a>
        </p>
        <div className="flex justify-center">
          <button
            type="button"
            className="bg-primary text-primary-foreground rounded-md text-lg py-4 px-6 md:text-xl"
          >
            <Link to="/register/subscriptions">¡Quiero mi suscripción!</Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
