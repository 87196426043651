import Header from "./Header";
import HeroImage from "./HeroImage";
import Boxes from "./Boxes";
import Press from "./Press";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import OurImpact from "./OurImpact";
import MaifudLovers from "./MaifudLovers";
import Problem from "./Problem";
import FAQ from "./FAQ";
import Footer from "./Footer";
import { subscriptionsDataQuery } from "@/services/queries";
import { useUser } from "@/firebase";

const Home = () => {
  const { data: response } = subscriptionsDataQuery({});
  const data = response?.data;
  const discountFormatted = data?.subscriptions[0].discount_formatted;
  const { user, isLoading: isLoadingUser } = useUser();

  return (
    <div className="bg-white">
      <Header
        discountFormatted={discountFormatted}
        user={user}
        isLoadingUser={isLoadingUser}
      />
      <HeroImage />
      <Features />
      <Boxes
        subscriptions={data?.subscriptions}
        showInitialDiscount={user == null && !isLoadingUser}
      />
      <Press />
      <HowItWorks />
      <OurImpact />
      <MaifudLovers />
      <Problem />
      <FAQ />
      <Footer />
    </div>
  );
};

export default Home;
