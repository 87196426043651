import { TableCell, TableRow } from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  TrashIcon,
  PlusCircledIcon,
  MinusCircledIcon,
  ResetIcon,
} from "@radix-ui/react-icons";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

type ProductStatus = "CLEAN" | "DELETED" | "INCREASED" | "DECREASED";

export type ProductProps = {
  status: ProductStatus;
  quantity: number;
  unitType: string;
  name: string;
  editMode: boolean;
  onDelete: (name: string) => void;
  onIncrease: (name: string) => void;
  onDecrease: (name: string) => void;
  onReset: (name: string) => void;
};

export type ProductType = Omit<
  ProductProps,
  "onDelete" | "onIncrease" | "onDecrease" | "editMode" | "onReset"
>;

const Product = ({
  status,
  quantity,
  unitType,
  name,
  editMode,
  onDecrease,
  onIncrease,
  onDelete,
  onReset,
}: ProductProps) => {
  const renderActions = () => {
    return (
      <>
        {status === "CLEAN" ? (
          <div className="flex gap-2 justify-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    className="p-[6px]"
                    onClick={() => onDelete(name)}
                  >
                    <TrashIcon className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Sacar {name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    className="p-[6px]"
                    disabled={quantity === 1}
                    onClick={() => onDecrease(name)}
                  >
                    <MinusCircledIcon className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent className={cn(quantity === 1 && "hidden")}>
                  <p>Disminuir {name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    className="p-[6px]"
                    onClick={() => onIncrease(name)}
                  >
                    <PlusCircledIcon className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Aumentar {name}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        ) : (
          <div className="flex gap-4 justify-end items-center">
            <span className="text-[10px] md:text-xs text-right leading-none font-semibold">
              {status === "DELETED" && <>Sacar {name}</>}
              {status === "INCREASED" && <>Aumentar {name}</>}
              {status === "DECREASED" && <>Disminuir {name}</>}
            </span>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    className="p-2"
                    onClick={() => onReset(name)}
                  >
                    <ResetIcon className="w-4 h-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Deshacer</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </>
    );
  };

  return (
    <TableRow
      key={name}
      className={cn(
        editMode && status === "DELETED" && "!border-l-8 border-l-red-500",
        editMode && status === "INCREASED" && "!border-l-8 border-l-green-500",
        editMode && status === "DECREASED" && "!border-l-8 border-l-yellow-500",
      )}
    >
      {editMode ? (
        <TableCell className="font-sans text-sm">
          {quantity} <span className="lowercase">{unitType}</span>{" "}
          <span>{name}</span>
        </TableCell>
      ) : (
        <>
          <TableCell className="font-sans text-sm">
            {quantity} <span className="lowercase">{unitType}</span>
          </TableCell>
          <TableCell className="text-sm">{name}</TableCell>
        </>
      )}

      <TableCell>{editMode ? renderActions() : null}</TableCell>
    </TableRow>
  );
};

export default Product;
