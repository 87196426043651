import {
  Loading,
  RetryErrorMessage,
  ShippingForm,
  TableItem,
} from "@/components";
import { AlertConfirmation } from "@/components";
import {
  userShippingDataQuery,
  userShippingDataMutation,
} from "@/services/queries";
import type { HomeDeliveryData } from "@/components/ShippingForm/HomeDelivery";
import type { PickUpDeliveryData } from "@/components/ShippingForm/PickupDelivery";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";

type ShippingEditProps = {
  onGoBack?: () => void;
  onSuccess?: () => void;
};

const ShippingEdit = ({ onGoBack }: ShippingEditProps) => {
  const {
    data: response,
    isLoading,
    isError,
    error,
    refetch,
  } = userShippingDataQuery();
  const data = response?.data;
  const [valuesToSubmit, setValuesToSubmit] = useState<
    HomeDeliveryData | PickUpDeliveryData
  >();
  const [shippingCost, setShippingCost] = useState<string>();

  const mutation = userShippingDataMutation();

  // Vibrate on success. Only for andriod
  useEffect(() => {
    if (navigator && navigator.vibrate) navigator.vibrate(200);
  }, [mutation.isSuccess]);

  const handleConfirmation = () => {
    if (valuesToSubmit) {
      mutation.mutate(valuesToSubmit);
    }
  };

  const handleSubmit = (
    values: HomeDeliveryData | PickUpDeliveryData,
    shippingCost: string,
  ) => {
    setShippingCost(shippingCost);
    setValuesToSubmit(values);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading message="🔍 Buscando tus direccíones..." />;
    }

    if (isError) {
      return (
        <RetryErrorMessage
          title={"No se pudo obtener la información de tus direccíones"}
          message={error.message}
          retry={refetch}
          error={error}
        />
      );
    }

    if (data) {
      return (
        <ShippingForm
          initialData={data}
          isLoading={mutation.isPending}
          isError={mutation.isError}
          isSuccess={mutation.isSuccess}
          error={error}
          handleSubmitHomeDeliveryForm={handleSubmit}
          handleSubmitPickUpForm={handleSubmit}
          onGoBack={onGoBack}
        />
      );
    }
  };

  const renderChangesSummary = () => {
    if (valuesToSubmit) {
      if (
        valuesToSubmit.selected_shipping_type_humanized === "Retiro en tiendas"
      ) {
        const changes = valuesToSubmit as PickUpDeliveryData;
        return (
          <div>
            <TableItem
              title={"Método de envío"}
              content={changes.selected_shipping_type_humanized}
            />
            <TableItem
              title={"Comuna"}
              content={changes.selected_pickup_district_name}
            />
            <TableItem
              title={"Costo de envío al punto de retiro"}
              content={shippingCost}
            />
            <TableItem
              title={"Punto de retiro"}
              content={changes.selected_pickup_point_name}
            />
          </div>
        );
      } else {
        const changes = valuesToSubmit as HomeDeliveryData;
        return (
          <div>
            <TableItem
              title={"Método de envío"}
              content={changes.selected_shipping_type_humanized}
            />
            <TableItem
              title={"Dirección"}
              content={changes.selected_delivery_address}
            />
            <TableItem
              title={"Número depto / Casa"}
              content={changes.selected_delivery_address_dpto}
            />
            <TableItem
              title={"Comuna"}
              content={changes.selected_delivery_district_name}
            />
            <TableItem title={"Costo de envío"} content={shippingCost} />
            {changes.selected_delivery_additional_data ? (
              <TableItem
                title={"Información adicional"}
                content={changes.selected_delivery_additional_data}
              />
            ) : null}
          </div>
        );
      }
    }
  };

  return (
    <div>
      <Label className="mb-2 block">
        Tu método de envío actual es:{" "}
        <strong className="text-primary">
          {data?.selected_shipping_type_humanized}
        </strong>
      </Label>
      <div>{renderContent()}</div>
      <AlertConfirmation
        open={valuesToSubmit !== undefined}
        title="¿Estás de acuerdo con tu nuevo método de envío?"
        onClose={() => {
          setValuesToSubmit(undefined);
          mutation.reset();
        }}
        onConfirm={handleConfirmation}
        isLoading={mutation.isPending}
        errorMessage={
          mutation.isError ? (
            <p className="text-center text-sm text-red-500">
              🫤 Hubo un error al intentar cambiar tu método de envío. Intenta
              de nuevo más tarde.
            </p>
          ) : undefined
        }
        successMessage={
          mutation.isSuccess ? (
            <p className="text-center text-sm">
              ✅ Tus cambios han sido guardados. Puedes cerrar está ventana.
            </p>
          ) : undefined
        }
      >
        {renderChangesSummary()}
      </AlertConfirmation>
    </div>
  );
};

export default ShippingEdit;
