import { Link } from "@tanstack/react-router";
import maifudLogo from "@/assets/maifud_logo.png";
import { MenuIcon, User2Icon } from "lucide-react";
import { Sheet, SheetContent, SheetHeader } from "@/components/ui/sheet";
import { useState } from "react";
import { Separator } from "@/components/ui/separator";
import { useNavigate } from "@tanstack/react-router";
import { logout } from "@/firebase";
import { Skeleton } from "@/components/ui/skeleton";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import type { User } from "firebase/auth";
import Banner from "./Banner";

const MENU_ITEMS = [
  { href: "#boxes-section", title: "Nuestras cajas", emoji: "📦" },
  { href: "#how-it-works-section", title: "¿Como funciona?", emoji: "💡" },
  { href: "#impact-section", title: "Impacto ambiental", emoji: "🌳" },
  { href: "#maifud-lovers-section", title: "MaifudLovers", emoji: "🧡" },
  { href: "#faq-section", title: "Preguntas frecuentes", emoji: "❓" },
];

type HeaderProps = {
  discountFormatted?: string;
  user: User | null;
  isLoadingUser: boolean;
};

const Header = ({ discountFormatted, user, isLoadingUser }: HeaderProps) => {
  const navigate = useNavigate();
  const [sectionsIsOpen, setSectionsIsOpen] = useState(false);

  const handleLogout = () => {
    logout().finally(() => {
      navigate({
        to: "/",
      });
    });
  };

  const shouldRenderBanner =
    !isLoadingUser && user == null && discountFormatted;

  const renderUser = () => {
    if (isLoadingUser) {
      return <Skeleton className="text-sm w-[76px] h-[19px]" />;
    }

    return (
      <>
        {user?.email ? (
          <small className="hidden md:block">Ir a tu cuenta</small>
        ) : (
          <small className="hidden md:block">Iniciar sesión</small>
        )}
      </>
    );
  };

  return (
    <nav className="bg-[#f6e9dd] sticky top-0 z-40">
      {shouldRenderBanner && <Banner discountFormatted={discountFormatted} />}
      <div className="max-w-6xl mx-auto flex justify-between items-center px-6 pt-6 pb-4 ">
        <Link to={"/"} className="flex items-baseline">
          <img alt="Maifud Logo" src={maifudLogo} className=" w-32 md:w-44" />
        </Link>
        <div className="flex flex-row space-x-4">
          <div>
            <Link
              to="/login"
              className="flex flex-col items-center"
              aria-label={user?.email ? "Ir a tu cuenta" : "Iniciar sesión"}
            >
              <User2Icon size={32} className="text-muted-foreground" />
              {renderUser()}
            </Link>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => setSectionsIsOpen(true)}
          >
            <MenuIcon size={32} className="text-muted-foreground" />
            <small className="hidden md:block">Secciones</small>
          </div>
        </div>
      </div>
      <Sheet open={sectionsIsOpen}>
        <SheetContent
          className="w-[300px]"
          onPointerDownOutside={() => setSectionsIsOpen(false)}
          onClose={() => setSectionsIsOpen(false)}
        >
          <SheetHeader>
            <img alt="Maifud Logo" src={maifudLogo} className="w-32" />
          </SheetHeader>

          {user?.email ? (
            <>
              <h2 className="text-2xl font-bold tracking-tight mt-10 mb-4">
                ¡Hola! 👋
              </h2>
              <ul className="flex flex-col mb-4">
                <li className="w-full" onClick={() => setSectionsIsOpen(false)}>
                  <Link
                    to="/user/subscription"
                    className="px-0 py-2 block text-left underline"
                  >
                    Ir a tu cuenta
                  </Link>
                </li>
                <li className="w-full" onClick={() => setSectionsIsOpen(false)}>
                  <button
                    type="button"
                    className="px-0 py-2 block text-left underline"
                    onClick={handleLogout}
                  >
                    Cerrar sesión
                  </button>
                </li>
              </ul>
              <Separator className="mb-4" />
            </>
          ) : (
            <>
              <h2 className="text-2xl font-bold tracking-tight mt-10 mb-4">
                ¡Hola! 👋
              </h2>
              <ul className="flex flex-col mb-4">
                <li className="w-full" onClick={() => setSectionsIsOpen(false)}>
                  <Link
                    to="/login"
                    className="px-0 py-2 block text-left underline"
                  >
                    Iniciar sesión
                  </Link>
                </li>
              </ul>
              <Separator className="mb-4" />
            </>
          )}

          <div className="relative">
            <ScrollArea className="h-[400px] w-[250px] pb-4">
              <h2 className="text-2xl font-bold tracking-tight mt-6 mb-4">
                <div>Suscripción</div>
              </h2>

              <ul className="flex flex-col space-y-2 mb-4">
                {MENU_ITEMS.map(({ href, title, emoji }) => (
                  <li
                    className="w-full"
                    onClick={() => setSectionsIsOpen(false)}
                    key={title}
                  >
                    <Link
                      key={href}
                      to={href}
                      className="px-0 py-2 block text-left rounded-md"
                    >
                      {emoji} <span className="underline">{title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
              <Separator className="mb-8" />
              <h2 className="text-2xl font-bold tracking-tight mt-6 mb-4">
                <div>Más sobre Maifud</div>
              </h2>
              <ul className="space-y-4">
                <li>
                  <a
                    className="underline"
                    href="https://www.maifud.cl/el-problema"
                  >
                    El problema
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    href="https://www.maifud.cl/nosotros"
                  >
                    Nosotros
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    href="https://www.maifud.cl/despacho"
                  >
                    Despachos
                  </a>
                </li>
                <li>
                  <a className="underline" href="https://www.maifud.cl/blog">
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    className="underline"
                    href="https://www.maifud.cl/productos"
                  >
                    Tienda granel
                  </a>{" "}
                  🍊
                </li>
              </ul>
              <ScrollBar />
            </ScrollArea>
          </div>
        </SheetContent>
      </Sheet>
    </nav>
  );
};

export default Header;
